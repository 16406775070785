import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"


export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }


  render() {
    return (
      <Layout>
        <SEO title="Uppsetning fyrir Heima er best"
        pathname="/setup/"
          />

         <Hero 
         headerText="Uppsetning" 
         subHeaderText="Leiðbeiningar fyrir uppsetningu í Bókun"
         heroBanner="https://images.squarespace-cdn.com/content/v1/52a74d9ae4b0253945d2aee9/1390513380961-PTHFXE5U2S1FJSLPXUSD/ke17ZwdGBToddI8pDm48kA2MStDQoZ8TsVhOdZz9ancUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKc02ZN02e9uXhS_VCIvN0BPHpXNLjw_J6OzsHaJFwWx58AMuMC3AT9ww3KmldDNe7v/tumblr_mh1iruZWLf1rkz363o1_1280.jpg?format=1500w" /> 

        <section className="section content column is-10 is-offset-1">
          <div className="columns">
            <div className="column is-10 offset-2">
              <h2>Það er einfalt að setja upp það sem til þarf</h2>
              
              <p>Við erum með einfalda leið til að setja upp vörurnar og selja þær beint til ykkar án þess milliliða. Í einföldu máli þarf að gera sölusamning í Bókun sem og nýja sölurás. Salan fer alltaf beint í gegnum birgja. </p>

              <h3>1) Kveikja á Íslensku í Company Settings</h3>
              <p><a href="https://extranet.bokun.io/vendor/edit">https://extranet.bokun.io/vendor/edit</a> </p>
              <p><img src="/img/veljaislensku.png" alt="Velja íslensku í tungumál" /></p>
              

              <h3>2) Búa til nýtt Booking channel sem er á Íslensku og senda okkur UUID og Vendor ID (neðst í vinstra horni)</h3>
              <p><a href="https://extranet.bokun.io/v2/online-sales/channels">https://extranet.bokun.io/v2/online-sales/channels</a></p>
              <p><img src="/img/bookingchannel.png" alt="Búa til Heima er best sem booking channel" /></p>



              <h3>3) Stilla greiðslugáttina ykkar</h3>
              <p><img src="/img/greidslugatt.png" alt="Passa að rétt greiðslugátt sé valin" /></p>

              <h3>4) Velja íslensku sem viðmót</h3>
              <p><img src="/img/islensktchannel.png" alt="Passa að velja íslensku sem tungumál" /></p>

              <h3>5) Senda marketplace samning á Heima er best</h3>
              <p>Best er að velja bara þær vörum sem þið teljið henta fyrir íslenska markaðinn. ATH það er í lagi að vera með  0% commission, er aldrei notað og skiptir í raun ekki máli.</p>
              <p><a href="https://extranet.bokun.io/contract/for/seller/41628">https://extranet.bokun.io/contract/for/seller/41628</a></p>

              <p>ATH ekki verra að vera með sér contract terms og sér price lista til að geta stjórnað íslenskum verðum á þessum vef án þess að það hafi áhrif á öðrum en það er ákvörðun sem þið þurfið að taka sjálf. Einnig er hægt að skoða að vera með promo codes</p>

              <h3>6) Þýða þær vörur sem þið viljið selja á Íslensku</h3>
              <p><img src="/img/thydaislensku.png" alt="Passa að velja íslensku sem tungumál" /></p>
              
              

            </div>
          </div>
             
              
              
             
        </section>
      </Layout>
    )
  }
}
